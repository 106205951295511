.topbar
{
    // padding: 10px 0;

    // background-color: $topbar-background-color;
    .topbar-wrapper
    {
        // display: flex;
        // align-items: center;
        background-color: #1d3649;
        // margin-left: -15px;
        // margin-right: -15px;
        text-align: center;
    }
    a
    {
        font-size: 1.5em;
        font-weight: bold;

        display: flex;
        align-items: center;
        flex: 1;

        max-width: 300px;

        text-decoration: none;

        @include text_headline($topbar-link-font-color);

        span
        {
            margin: 0;
            padding: 0 10px;
        }
    }

    .download-url-wrapper
    {
        display: flex;
        flex: 3;
        justify-content: flex-end;

        input[type=text]
        {
            width: 100%;
            margin: 0;

            border: 2px solid $topbar-download-url-wrapper-element-border-color;
            border-radius: 4px 0 0 4px;
            outline: none;
        }

        .select-label
        {
            display: flex;
            align-items: center;

            width: 100%;
            max-width: 600px;
            margin: 0;
            color: #f0f0f0;
            span
            {
                font-size: 16px;

                flex: 1;

                padding: 0 10px 0 0;

                text-align: right;
            }

            select
            {
                flex: 2;

                width: 100%;

                border: 2px solid $topbar-download-url-wrapper-element-border-color;
                outline: none;
                box-shadow: none;
            }
        }


        .download-url-button
        {
            font-size: 16px;
            font-weight: bold;

            padding: 4px 30px;

            border: none;
            border-radius: 0 4px 4px 0;
            background: $topbar-download-url-button-background-color;

            @include text_headline($topbar-download-url-button-font-color);
        }
    }
    .topbar-label{
        font-size: 16px;
        font-weight: bold;
        color: #f0f0f0;
        
    }

    #title {
        padding-top: 10px;
        padding-bottom: 15px;
    }

    #logo {
        display: inline;
        font-size: 1.5em;
        font-weight: bold;
        text-decoration: none;
        // background: transparent url(../../../images/xforce-logo-small.png) no-repeat left center;
        padding: 20px 20px 10px 10px;
        color: white;
      }

      .api_selector{
        display: inline;
      }

      .api_selector .topbar-label{
        padding-left: 15px;
        padding-right: 5px;
      }

      .api_selector .api_texbox{
        width: 25%;
    }

    span{
        display: block;
        clear: none;
        padding-bottom: 15px;
    }

    .btn-authenticate {
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
        margin-left: 20px;
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }
}
